import React from "react"
import Layout from "../components/layout"

export default function Home() {
  return (
    <Layout>
      <h1>Spheemo</h1>
      <p>
        Welcome to Spheemo G.K. This company is just established. <br />
        The company provides the following: <br />
      </p>
      <p>
        <ul>
          <li>Product Management</li>
          <li>Consulting and build strong product development team with agile ways</li>
          <li>Some online services</li> 
        </ul>
      </p>
    </Layout>
    )
}
